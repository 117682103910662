import { NgModule } from '@angular/core';
import { AoFeatureShellModule } from '@ao/feature-shell';
import { environment } from '@ao/util-environment';
import { ScullyLibModule } from '@scullyio/ng-lib';

import { AppComponent } from './app.component';
import { COMPONENT_MAPPING } from './cms-config/cms-component-mapping.config';
import { gaTagsConfig } from './ga-tags-config/ga-tags-config';
import { cmsDataUrl } from './cms-config/cms-data-url';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AoFeatureShellModule.forRoot({
      COMPONENT_MAPPING,
      gaTagsConfig,
      cmsDataUrl,
    }),
    ScullyLibModule,
  ],
  providers: [
    { provide: 'isDevelopmentEnvironment', useValue: !environment.production }, // to enable debug logger
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
